import React from "react"
import { graphql } from "gatsby"
import BlogCategoryIndex from './blogCategoryIndex'

export default function Blog({ data, pageContext, location }) {
  return (<BlogCategoryIndex data={data.allWordpressPost} title={pageContext.name} location={location} />)
}

export const pageQuery = graphql`
  query ($slug: String!) {
    allWordpressPost(filter: {categories: {elemMatch: {slug: {eq: $slug}}}}) {
      edges {
        node {
          title
          excerpt
          slug
          fimg_url {
            source_url
          }
          featured_media {
            localFile {
              childImageSharp {
                fluid(quality: 90, maxWidth: 1920){
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
    file(relativePath: { eq: "images/fallbackImage.jpeg" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 1920){
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`